<template>
  <div class="p_invite">
    <div class="page_content">
      <img src="https://tcdn.papaen.com/assets/promote/web/banner-hungary.png" alt="">
      <div class="from_content">
        <div class="form_info">
          <el-input class="form_wechat" v-model="weChat" placeholder="填写微信帐号（非微信昵称），即可获得以上福利" clearable>
          </el-input>
        </div>
        <div class="form_button" @click="subMitWechat">立即领取</div>
        <div class="get-wechat-hint">
          * 我们的工作人员会在两个工作日内添加您给您发送以上免费福利
        </div>
      </div>
      <div class="about-us">// 关于我们</div>
      <div class="about-us-content">
        我们是趴趴英语<br/>
        从事雅思/托福/GMAT的专业在线练习培训团队的资深品牌<br/>
        目前我们已经成功帮助100w+的考生提高考试分数！<br/>

      </div>
      <img src="https://tcdn.papaen.com/assets/promote/web/footer.png">
    </div>
    <div class="promote-bg" v-if="isSubmitSuccess"></div>
    <div class="promote-content-wrap" v-if="isSubmitSuccess">
      <img src="https://tcdn.papaen.com/assets/promote/web/close.png" @click="closeDialog">
      <img src="https://tcdn.papaen.com/assets/promote/web/right.png">
      <div class="promote-content">我们已收到您的信息，会尽快联系您发送福利</div>
    </div>
  </div>
</template>

<script>
  import * as Api from '@/api/common/common'
  import { Message } from 'element-ui'

  export default {
    name: 'inviteSimple',
    data () {
      return {
        weChat: '',
        isSubmitSuccess: false,
      }
    },
    mounted(){
    },
    methods: {
      mouseOverSlider () {
        this.swiperBtn = true
      },
      mouseLeaveSlider () {
        this.swiperBtn = false
      },
      subMitWechat () {
        if (!this.weChat) {
          Message.error('请填写微信号')
          return
        }

        let params = {
          code: this.$route.query.code,
          category_id: 1,
          we_chat: this.weChat
        }
        Api.submitWechat(params, res => {
          this.isSubmitSuccess = true
        })
      },
      closeDialog () {
        this.isSubmitSuccess = false
      }
    }
  }
</script>

<style lang="less">
  .p_invite {
    max-width: 100%;

    background: #F5F6F8;

    .page_content {
      max-width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      font-size: 0;

      .from_content {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 8px 20px 0 #DDD9EE;
        border-radius: 5px;
        width: 652px;
        height: 148px;
        margin: 0 auto;
        padding: 40px 56px 60px;
        position: relative;
        bottom: 48px;
        z-index: 99;

        .form_info {
          height: 48px;
          display: flex;
          margin-bottom: 20px;

          .form_type {
            height: 100%;
            margin-right: 2%;

            .el-input {
              height: 100%;

              input {
                height: 100%;
              }
            }
          }

          .form_wechat {
            input {
              height: 100%;
            }
          }
        }

        .form_button {
          background: rgba(255, 216, 122, 1);
          border-radius: 5px;
          width: 100%;
          height: 65px;
          text-align: center;
          line-height: 65px;
          color: #5B46C5;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 20px;
          cursor: pointer;
        }

        .get-wechat-hint {
          color: #777777;
          font-size: 20px;
        }
      }

      .about-us {
        color: #4294D4;
        font-size: 26px;
        width: 764px;
        margin: 0 auto;
        font-weight: bold;
        position: relative;
        bottom: 17px;
      }

      .about-us-content {
        color: #777777;
        font-size: 20px;
        font-weight: 500;
        width: 764px;
        margin: 0 auto;
        margin-bottom: 30px;
        line-height: 33px;
      }

      & > .slider-content {
        font-size: 0;
        /*height: 400px;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*overflow: hidden;*/

        & > .swiper-container {
          width: 1200px;

          & > .swiper-wrapper {
            & > .swiper-slide {
              width: 100% !important;
              text-align: center;
              font-size: 18px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;

              & > img {
                width: 100%;
                height: 400px
              }
            }
          }
        }
      }
    }
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1;
  }

  .swiper-pagination-bullet {
    background: #fff !important;
  }

  /deep/ .my-bullet-active {
    background: #fff;
    opacity: 1;
  }

  .hide {
    display: none;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    color: #fff;
    left: 15px;
    width: 60px;
    height: 80px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 80px;
    border-radius: 5px;

    & > i {
      font-size: 40px;
    }

    i:before {
      margin-right: 0;
    }
  }

  .promote-bg {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background: rgba(27, 27, 27, .89);
    height: 100%;
    z-index: 999;
  }

  .promote-content-wrap {
    z-index: 999;
    position: fixed;
    width: 566px;
    height: 256px;
    background: #fff;
    border-radius: 3px;
    top: calc((100% - 256px) / 2);
    left: calc((100% - 566px) / 2);
  }

  .promote-content-wrap img:nth-child(1) {
    width: 18px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }

  .promote-content-wrap img:nth-child(2) {
    width: 76px;
    position: absolute;
    top: 60px;
    left: calc((100% - 76px) / 2);

  }

  .promote-content {
    color: #333;
    font-size: 20px;
    text-align: center;
    position: absolute;
    bottom: 36px;
    left: calc((100% - 400px) / 2);

  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 15px;
    color: #fff;
    text-align: left;
    width: 60px;
    height: 80px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 80px;
    border-radius: 5px;

    & > i {
      font-size: 40px;
    }

    i:before {
      margin-right: 0;
    }
  }

  .swiper-button-next:hover {
    color: #29D087;
  }

  .swiper-button-prev:hover {
    color: #29D087;
  }
</style>
